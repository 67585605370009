<template>
  <div class="card-item d-flex flex-column flex-sm-row align-sm-center pa-4">
    <div class="d-flex flex-row flex-grow-1 justify-space-between ml-5">
      <div>{{ indent + ' ' + plugin.name }}</div>
      <material-product-details-change-plan-plugins-switcher
        :plugin="plugin"
        :checked="checked"
        @plugin-change="onPluginChange"
      />
    </div>
    <div class="plugin-price-col">
      {{ plugin.price }}
    </div>
  </div>
</template>

<script>
import { INDENT } from '@/constants/app'

export default {
  props: {
    plugin: {
      type: Object,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      indent: INDENT
    }
  },
  methods: {
    onPluginChange (data) {
      this.$emit('plugin-change', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.plugin-price-col {
  width: 30%;
  text-align: right;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .plugin-price-col {
    width: 100%;
  }
}
</style>
